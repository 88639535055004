@font-face {
  font-family: 'gothic';
  font-style: normal;
  font-weight: normal;
  src: local('gothic'), url('./font/gothic.woff') format('woff');
}

body {
  margin: 0;
}

::-moz-selection {
  color: rgb(1, 48, 74);
  background: #FFBDA6;
}

::selection {
  color: rgb(1, 48, 74);
  background: #FFBDA6;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: white; 
}

::-webkit-scrollbar-thumb {
  background: #01304A; 
  border: 0px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  width: 100%;
}

.video-wrapper {
  width: 100%;
  max-width: 1000px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
}

.video-wrapper:hover .controls {
  transform: translateY(0%);
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 30px;
  padding: 14px;
  width: 100%;
  max-width: 600px;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateY(150%);
  transition: all 0.3s ease-in-out;
}

.actions button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.actions button i {
  background-color: none;
  color: white;
  font-size: 30px;
}

input[type="range"] {
  -webkit-appearance: none !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  height: 4px;
  width: 350px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  cursor: pointer;
  height: 6px;
}

input[type="range"]::-moz-range-progress {
  background: white;
}

.velocity {
  appearance: none;
  background: none;
  color: #FFBDA6;
  outline: none;
  border: none;
  text-align: center;
  font-size: 16px;
}

.mute-btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.mute-btn i {
  background-color: none;
  color: white;
  font-size: 20px;
}

.react-pdf__Page__canvas {
  display: inline !important;
}

.player{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ressourceCard {
  border-radius: 18px;
  transition: box-shadow 0.3s ease-in-out;
}

.ressourceCardWithoutElevation {
  border-radius: 18px;
}

.ressourceCard:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.rec-pagination {
  display: none !important;
}

.kXteup,.Slxdj,.hGRYOm,.hGPzOw {
  border-radius: none !important;
  background-color: #EBF2F5 !important;
  border: none !important;
  box-shadow: none !important;
}

.kXteup:hover {
  color: #FFBDA6 !important;
}

.hGRYOm:hover {
  color: #FFBDA6 !important;
}

@media ( -webkit-min-device-pixel-ratio: 1.25 ) {
    body {
      zoom: 0.8;
    }
    .login {
      height: 125vh !important;
    }
    .forceHeight {
      height: 125vh !important;
    }
}

.login {
  position: relative;
  background: #01304A;
  width: 100vw;
  max-width: 100%;
  min-width: 100%;
  height: 100vh;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

#google_translate_element {
  width:300px;
  float:right;
  text-align:right;
  display:block
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

#goog-gt-tt {
  display: none !important;
  top: 0px !important;
}

.goog-tooltip skiptranslate {
  display: none !important;
  top: 0px !important;
}

.activity-root {
  display: hide !important;
}

.status-message {
  display: hide !important;
}

.started-activity-container {
  display: hide !important;
}

.cutoff-text {
  --max-lines: 5;
  --line-height: 1.4;

  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height);

  overflow: hidden;
  position: relative;
}

.cutoff-text::before {
  content: "";
  position: absolute;
  height: calc(1em * var(--line-height));
  width: 100%;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(to bottom, transparent, white);
}