.sd-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  margin-top: calcSize(2);
}
.sd-page__row.sd-row {
  margin-top: calcSize(2);
}
.sd-page__row.sd-row--multiple,
.svc-row > .sd-row--multiple {
  background: $background;
  box-shadow: 0px 1px 2px $shadow-inner;
  border-radius: calcSize(0.5);
}

:not(.svc-row) > .sd-row--multiple {
  row-gap: calcSize(1);
  margin-left: calc(-1 * var(--sd-base-padding));
  width: calc(100% + var(--sd-base-padding));
  flex-wrap: wrap;

  > div {
    box-sizing: border-box;
    padding-left: var(--sd-base-padding);
  }
}

:not(.svc-row) > .sd-page__row.sd-row--multiple {
  padding: 0 var(--sd-base-padding) var(--sd-base-padding) 0;
  margin-left: 0;
  width: 100%;
}

.svc-row .sd-row--multiple {
  overflow: auto;
  margin-top: calcSize(2);
}

.svc-row--multiple {
  .sd-row--multiple {
    gap: calcSize(1);
  }
}

:not(.svc-row--ghost) > .sd-row {
  min-height: 50px;
}

.sd-row__panel {
  box-sizing: border-box;
  width: 100%;
}

.sd-row__question {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
}
