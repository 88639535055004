$font-family: var(--font-family);

.sv_progress-toc {
  padding: calcSize(1);
  max-width: calcSize(42);
  height: 100%;
  background: $background;
  border-right: 1px solid $border;
  box-sizing: border-box;
  min-width: calcSize(32);

  .sv-list__item.sv-list__item--selected .sv-list__item-body {
    background: $primary-light;
    color: $foreground;
    font-weight: 400;
  }

  .sv-list__item span {
    white-space: break-spaces;
  }

  .sv-list__item-body {
    padding-inline-start: calcSize(2);
    padding-inline-end: calcSize(2);
    border-radius: 3px;
    padding-top: calcSize(1.5);
    padding-bottom: calcSize(1.5);
  }
}