.App {
    text-align: center;
    background-color: #01304A;
    width: 100vw;
    height: 100%;
    /* display: flex; */
    color: white;
    margin-top: -11px;
}
  
nav {
    height: 60px;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid grey;
    display: grid;
    place-items: center;
}
  
nav h1 {
    margin: 0;
    font-family: Gothic, Arial, sans-serif;
    color: white;
    font-size: 45px;
}
.game {
    width: 100vw;
    height: calc(100vh - 192px);
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 15px;
    flex-direction: column;
}

.board {
    width: 450px;
    height: 550px;
    display: flex;
    flex-direction: column;
}

.row {
    flex: 33%;
    display: flex;
    flex-direction: row;
    margin: 5px;
}

.letter {
    flex: 33%;
    height: 100%;
    border: 1px solid grey;
    border-radius: 10%;
    margin: 5px;
    display: grid;
    place-items: center;
    font-size: 30px;
    font-weight: bolder;
    color: white;
    font-family: Gothic, Helvetica, sans-serif;
}

#correct {
    background-color: #ACCDD8;
}

#almost {
    background-color: #FFBDA6;
}

#error {
    background-color: #3a393c;
}

.keyboard {
    width: 700px;
    height: 300px;
    margin-top: 30px;
}

.line1 {
    flex: 33%;
    display: flex;
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin: 5px;
}
.line2 {
    flex: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5px;
}
.line3 {
    flex: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5px;
}

.key {
    width: 50px;
    height: 70px;
    margin: 5px;
    border-radius: 4px;
    display: grid;
    place-items: center;
    font-size: 20px;
    background-color: grey;
    color: white;
    font-family: Gothic, Helvetica, sans-serif;
    cursor: pointer;
}

#big {
    width: 100px;
}
#disabled {
    background-color: #3a393c;
}

@media (max-width: 400px) {
    .board {
        width: 350px;
        height: 500px;
        margin-top: -10px;
    }

    .keyboard {
        width: 350px;
        height: 300px;
        margin-top: 10px;
    }

    .key {
        height: 35px;
        margin: 3px;
        font-size: 17px;
    }
}

@media ( -webkit-min-device-pixel-ratio: 1.25 ) {
    .game {
        width: 125vw !important;
        height: calc(125vh - 192px) !important;
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 15px;
        flex-direction: column;
    }
}