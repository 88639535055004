.correctAnswer {
    color: green;
}
.incorrectAnswer {
    color: red;
}
.sd-element__title.sd-element__title--disabled {
    opacity: 1;
}

#surveyElement {
    --primary: #ffbda4;
    --background: #ffffff;
    --background-dim: #ffffff;
    --background-dim-light: #ffffff;
    --primary-foreground: #01304a;
    --foreground: #01304a;
    --base-unit: 8px;
}

body {
    --primary: #ffbda4;
    --background: #ffffff;
    --background-dim: #ffffff;
    --background-dim-light: #ffffff;
    --primary-foreground: #01304a;
    --foreground: #01304a;
    --base-unit: 8px;
}

.sv-action__content {
    display: none;
}

.sd-container-modern {
    margin-bottom: 0px;
}

.navigation-block {
    background-color: #F3F3F3;
}

.sd-btn:not(:disabled):hover, .sd-btn:not(:disabled):focus {
    box-shadow: 0 0 2px #01304a;
}